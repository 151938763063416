import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import Head from "../components/head/head";
import SendToMobileIcon from "@mui/icons-material/SendToMobile";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";

import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";

import CustomeCarousel from "../components/carousel/carousel";
import PrivacyPolicy from "./terms-condition-privacy-policy/PrivacyPolicy";
import TermsAndCondition from "./terms-condition-privacy-policy/TermsAndCondition";

const images = [
  {
    url: "/images/01.png",
    name: "welcome screen",
  },
  {
    url: "/images/02.png",
    name: "Home screen",
  },
  {
    url: "/images/03.png",
    name: "News screen",
  },
  {
    url: "/images/04.png",
    name: "Hashtag screen",
  },
  {
    url: "/images/05.png",
    name: "Appointment screen",
  },
];

const AboutUs = () => {
  return (
    <>
      <Head title={"About Us"} description={"This is about page"} />
      <Box
        height={"250px"}
        width={"100%"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        bgcolor={"secondary.main"}
      >
        <Typography
          variant="h2"
          textAlign={"center"}
          color={"primary"}
          sx={{
            textShadow: "1px 1px 3px #353535",
            color: "#E9901C",
            fontWeight: 600,
          }}
        >
          APE BUZZ
        </Typography>
      </Box>
      <Container maxWidth="lg">
        <Stack width={"100%"} pb={2}>
          <Stack rowGap={2} pb={5}>
            <Typography
              variant="h5"
              fontWeight={"bold"}
              color={"primary"}
              mt={2}
            >
              Download Our App
            </Typography>
            <CustomeCarousel images={images} />
          </Stack>
          <Typography variant="h5" fontWeight={"bold"} color={"#E9901C"}>
            About Us
          </Typography>
          <Divider />
          <Grid mt={2} container rowGap={4}>
            <Grid item xs={12} md={8} component={Paper} p={2} elevation={0}>
              <Typography
                variant="h6"
                fontWeight={"bold"}
              >
                About APEBUZZ
              </Typography>
              <Stack>
                
                {/* <Divider /> */}
                <Stack
                  mt={2}
                  sx={{
                    // height: "450px",
                    overflowY: "auto",
                    overflowX: "hidden",
                    "&::-webkit-scrollbar": {
                      width: "6px",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "transparent",
                      borderRadius: "5px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      //   backgroundColor: "#FB8C00",
                      backgroundColor: "transparent",

                      borderRadius: "10px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      //   backgroundColor: "#FB8C00",
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  {/* <Typography
                    variant="body2"
                    textAlign={"start"}
                    fontWeight={"bold"}
                  >
                    Post and New
                  </Typography> */}
                  <Typography
                    lineHeight={"30px"}
                    sx={{ wordSpacing: "2px", letterSpacing: "3px" }}
                    variant="body2"
                    textAlign={"justify"}
                    component={"p"}
                  >
                    Welcome to ApeBuzz.
                    What is AP-e? Its “Augmented Personalised
                    Experience”, with minimalised approach towards digitalised
                    connection.
                    Why ApeBuzz? Picture a platform where
                    interactions are distilled to their essence, free from the
                    clutter and chaos of traditional social media. A place where
                    robust privacy settings protect your digital footprint, and
                    an intuitive, clean design makes navigation a joy.
                    At the heart of digital evolution lies a need for genuine,
                    meaningful connections. Enter ApeBuzz, an innovation from
                    the creators at ApeLabs. Imagine a realm where every
                    interaction is effortless, memorable, and impactful—this is
                    the world ApeBuzz is crafting. 
                    In today’s whirlwind of
                    content, finding substance can be a challenge. ApeBuzz
                    emerges as a beacon of simplicity, turning the tide with our
                    unique “Buzz” system. Here, less truly means more, and every
                    connection resonates deeply. Rooted in the same innovative
                    spirit that drives ApeLabs’ premium, user-centric
                    electronics, ApeBuzz blends this legacy with a fresh
                    approach to social media. We envision a space where quality
                    trumps quantity, where privacy is paramount, and where every
                    user feels seen and valued. We invite you to experience a
                    new kind of social interaction. Join us at ApeBuzz, where
                    the future of meaningful connection is not just imagined,
                    but lived. Welcome to the next wave of social media, brought
                    to you by the innovators at ApeLabs.
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid
              item
              display={"flex"}
              justifyContent={"center"}
              alignItems={"start"}
              xs={12}
              md={4}
            >
              <Stack>
                <Box>
                  <img
                    src="/logo/appBuzzLogo.svg"
                    alt="logo"
                    height={"150px"}
                    width={"150px"}
                  />
                </Box>
                <Box>
                  <Typography fontWeight={"bold"} textAlign={"start"}>
                    Contact Us
                  </Typography>
                  <List dense>
                    <ListItem
                      //   secondaryAction={
                      //     <IconButton edge="end" aria-label="delete">
                      //       <DeleteIcon />
                      //     </IconButton>
                      //   }
                      color="primary"
                    >
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: "#E9901C" }}>
                          <PhoneIphoneIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Contact"
                        secondary={"+918108105600"}
                      />
                    </ListItem>
                    <ListItem
                    //   secondaryAction={
                    //     <IconButton edge="end" aria-label="delete">
                    //       <DeleteIcon />
                    //     </IconButton>
                    //   }
                    >
                      <ListItemAvatar color="primary">
                        <Avatar sx={{ bgcolor: "#E9901C" }}>
                          <EmailIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Email"
                        secondary={"apeland@apeitnow.com"}
                      />
                    </ListItem>
                  </List>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </>
  );
};

export default AboutUs;
