import { Box } from "@mui/material";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 950 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 950, min: 525 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 525, min: 0 },
    items: 1,
  },
};

const CustomeCarousel = ({ images }) => {
  return (
    <>
      <Carousel
        responsive={responsive}
        additionalTransfrom={0}
        arrows
        autoPlay={true}
        autoPlaySpeed={2}
        centerMode={false}
        containerClass="container-with-dots"
        customTransition="all 1s linear"
        draggable
        focusOnSelect={false}
        infinite
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderDotsOutside={false}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        slidesToSlide={0}
        swipeable
        transitionDuration={4000}
      >
        {images?.map((_el, index) => {
          return (
            <Box
              key={index}
              //   color="black"
              sx={{
                width: "300px",
                height: "500px",
                marginLeft: "auto",
                marginRight: "auto",
                p: 0.2,
              }}
            >
              <Box
                component={"img"}
                src={_el.url}
                alt={_el?.name}
                height={"100%"}
                width={"100%"}
                sx={{ borderRadius: "20px"}}
              />
            </Box>
          );
        })}
      </Carousel>
    </>
  );
};

export default CustomeCarousel;
